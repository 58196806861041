var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search-properties" }, [
    _c(
      "div",
      [
        _c("el-date-picker", {
          staticClass: "button-left-status",
          staticStyle: { width: "100px" },
          attrs: {
            clearable: false,
            "value-format": "yyyy",
            type: "year",
            placeholder: "Chọn năm",
          },
          on: { change: _vm.searchByProperties },
          model: {
            value: _vm.dataSearch.year,
            callback: function ($$v) {
              _vm.$set(_vm.dataSearch, "year", $$v)
            },
            expression: "dataSearch.year",
          },
        }),
        _c(
          "el-select",
          {
            staticClass: "input-common",
            staticStyle: { width: "140px", "margin-left": "5px" },
            attrs: { placeholder: "Trạng thái", clearable: "" },
            on: { change: _vm.handleChangeEmployeeStatus1 },
            model: {
              value: _vm.dataSearch.employeeStatus,
              callback: function ($$v) {
                _vm.$set(_vm.dataSearch, "employeeStatus", $$v)
              },
              expression: "dataSearch.employeeStatus",
            },
          },
          _vm._l(_vm.employeeStatusListExcel1, function (item) {
            return _c("el-option", {
              key: item.key,
              attrs: { value: item.value, label: item.value },
            })
          }),
          1
        ),
      ],
      1
    ),
    _c("div", { staticStyle: { "margin-top": "20px" } }, [
      _c(
        "div",
        [
          _c(
            "el-row",
            { staticStyle: { margin: "0 0 10px 0" }, attrs: { gutter: 50 } },
            [
              _c(
                "el-col",
                {
                  staticStyle: { color: "white" },
                  attrs: { offset: 6, span: 6 },
                },
                [
                  _c("div", { staticClass: "grid-content bg-purple" }, [
                    _c("div", { staticClass: "content-line-1 color-1" }, [
                      _c("i", { staticClass: "el-icon-chat-line-square" }),
                      this["loda"].sumBy(this.dataListOrder1) !== 0
                        ? _c("span", [
                            _vm._v(
                              "Đã hoàn thành: " +
                                _vm._s(this["loda"].sumBy(this.dataListOrder1))
                            ),
                          ])
                        : _c("span", [_vm._v("Đã hoàn thành: 0")]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticStyle: { color: "white" }, attrs: { span: 6 } },
                [
                  _c("div", { staticClass: "grid-content bg-purple" }, [
                    _c("div", { staticClass: "content-line-1 color-2" }, [
                      _c("i", { staticClass: "el-icon-edit-outline" }),
                      this["loda"].sumBy(this.dataListOrder2) !== 0
                        ? _c("span", [
                            _vm._v(
                              "Chưa hoàn thành: " +
                                _vm._s(this["loda"].sumBy(this.dataListOrder2))
                            ),
                          ])
                        : _c("span", [_vm._v("Chưa hoàn thành: 0")]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm.loadChart
            ? _c("spinner-custom")
            : _c(
                "div",
                [
                  _vm.showChartOrder
                    ? _c("ChartBar", {
                        attrs: {
                          checkLable: true,
                          chartData: _vm.dataConnectionOrder.chartData,
                          options: _vm.dataConnectionOrder.options,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
      _c("div", [
        _c(
          "div",
          {
            staticClass: "search-table",
            staticStyle: { "margin-top": "50px" },
          },
          [
            _c("el-date-picker", {
              staticClass: "button-left-status",
              staticStyle: { width: "100px" },
              attrs: {
                clearable: false,
                "value-format": "yyyy",
                type: "year",
                placeholder: "Chọn năm",
              },
              on: { change: _vm.searchByPropertiesTuition },
              model: {
                value: _vm.dataSearchTuition.year,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearchTuition, "year", $$v)
                },
                expression: "dataSearchTuition.year",
              },
            }),
            _c(
              "el-select",
              {
                staticClass: "input-common",
                staticStyle: { width: "140px" },
                attrs: { placeholder: "Trạng thái", clearable: "" },
                on: { change: _vm.searchByPropertiesTuition },
                model: {
                  value: _vm.dataSearchTuition.employeeStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearchTuition, "employeeStatus", $$v)
                  },
                  expression: "dataSearchTuition.employeeStatus",
                },
              },
              _vm._l(_vm.employeeStatusListExcel2, function (item) {
                return _c("el-option", {
                  key: item.key,
                  attrs: { value: item.value, label: item.value },
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                staticStyle: { width: "180px" },
                attrs: { placeholder: "Trạng thái kết chuyển", clearable: "" },
                on: { change: _vm.searchByPropertiesTuition },
                model: {
                  value: _vm.dataSearchTuition.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearchTuition, "status", $$v)
                  },
                  expression: "dataSearchTuition.status",
                },
              },
              _vm._l(_vm.employeeStatusTransition, function (item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { value: item.value, label: item.name },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-row",
              {
                staticStyle: { margin: "30px 0 10px 0" },
                attrs: { gutter: 50 },
              },
              [
                _c(
                  "el-col",
                  { staticStyle: { color: "white" }, attrs: { span: 6 } },
                  [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _c("div", { staticClass: "content-line-1 color-1" }, [
                        _c("i", { staticClass: "el-icon-chat-line-square" }),
                        this["loda"].sumBy(this.dataListTuitionRevenue1) !== 0
                          ? _c("span", [
                              _vm._v(
                                "Thiếu thừa tháng truớc: " +
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      this["loda"].sumBy(
                                        this.dataListTuitionRevenue1
                                      )
                                    )
                                  )
                              ),
                            ])
                          : _c("span", [_vm._v("Thiếu thừa tháng truớc: 0 đ")]),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "el-col",
                  { staticStyle: { color: "white" }, attrs: { span: 6 } },
                  [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _c("div", { staticClass: "content-line-1 color-2" }, [
                        _c("i", { staticClass: "el-icon-edit-outline" }),
                        this["loda"].sumBy(this.dataListTuitionRevenue2) !== 0
                          ? _c("span", [
                              _vm._v(
                                "Phải chi tháng này: " +
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      this["loda"].sumBy(
                                        this.dataListTuitionRevenue2
                                      )
                                    )
                                  )
                              ),
                            ])
                          : _c("span", [_vm._v("Phải chi tháng này: 0 đ")]),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "el-col",
                  { staticStyle: { color: "white" }, attrs: { span: 6 } },
                  [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _c("div", { staticClass: "content-line-1 color-3" }, [
                        _c("i", { staticClass: "el-icon-edit-outline" }),
                        this["loda"].sumBy(this.dataListTuitionRevenue3) !== 0
                          ? _c("span", [
                              _vm._v(
                                "Đã chi: " +
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      this["loda"].sumBy(
                                        this.dataListTuitionRevenue3
                                      )
                                    )
                                  )
                              ),
                            ])
                          : _c("span", [_vm._v("Đã chi: 0 đ")]),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "el-col",
                  { staticStyle: { color: "white" }, attrs: { span: 6 } },
                  [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _c("div", { staticClass: "content-line-1 color-4" }, [
                        _c("i", { staticClass: "el-icon-edit-outline" }),
                        this["loda"].sumBy(this.dataListTuitionRevenue4) !== 0
                          ? _c("span", [
                              _vm._v(
                                "Thiếu thừa còn lại: " +
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      this["loda"].sumBy(
                                        this.dataListTuitionRevenue4
                                      )
                                    )
                                  )
                              ),
                            ])
                          : _c("span", [_vm._v("Thiếu thừa còn lại: 0 đ")]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm.loadChartTuitionRevenue
              ? _c("spinner-custom")
              : _c(
                  "div",
                  [
                    _vm.showChartOrder
                      ? _c("ChartBar", {
                          attrs: {
                            checkLable: true,
                            chartData:
                              _vm.dataConnectionTuitionRevenue.chartData,
                            options: _vm.dataConnectionTuitionRevenue.options,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }